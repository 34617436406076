import styles from "./styles.module.css";
import Logo from "../../assets/images/AEXP nova.png";

export const Header = () => {
  return (
    <nav className={styles.nav}>
      <img src={Logo} alt="Logo" />
    </nav>
  );
};
