import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaSpinner } from "react-icons/fa6";

import { Header } from "../../components/Header";
import { useAppContext } from "../../context/AppContext";

import styles from "./styles.module.css";
import StepSelector from "../../components/StepSelector/StepSelector";
import imgNotFound from "../../assets/images/not-found.png";


const Pocket: React.FC = () => {
  const { pin } = useParams<{ pin: string }>();
  const { event, setEvent } = useAppContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/pocket/eventbypin/${pin}`
        );
        setEvent(response.data.data);
      } catch (error: any) {
        console.log("error catch: ", error.message);
        console.log(error.response.data.message); // Define o evento como null em caso de erro
      } finally {
        setLoading(false); // Define loading como false ao final da requisição, seja bem-sucedida ou não
      }
    };

    fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!event) {
    return (
      <main className={styles.main}>
        <Header></Header>

        <div className={styles.container}>
          <div className={styles.content}>
            {!loading ? (
              <>
                <img
                  src={imgNotFound}
                  className={styles.notFound}
                  alt="notFound"
                />
                <h1>Evento não encontrado ou espirado</h1>
                <h2>Procure o administrador do evento</h2>
              </>
            ) : (
              <FaSpinner />
            )}
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className={styles.main}>
      <Header></Header>
      <StepSelector event={event} />
    </main>
  );
};

// export const getServerSideProps: GetServerSideProps = async (context) => {
//   const { NEXT_PUBLIC_API } = process.env;
//   const { AGORA_API } = process.env;
//   //const pin = context.params?.pin as string | undefined;
//   const { pin } = context.query;

//   console.log(NEXT_PUBLIC_API);
//   console.log(AGORA_API);
//   let event = null;
//   if (!pin) {
//     return {
//       props: {
//         event: null,
//       },
//     };
//   }

//   try {
//     const response = await axios.get(`${AGORA_API}/pocket/eventbypin/${pin}`);
//     event = response.data.data;
//     console.log(`Evento ${JSON.stringify(response.data.data)}`);
//   } catch (error) {
//     console.log("Erro ao buscar o jogos na page Evento: ");
//   }
//   return {
//     props: {
//       event: event ? event : null,
//     },
//   };
// };
export default Pocket;
